<template>
  <ion-page class="page scrollable">
    <div class="header">
      <h1>Cookie Settings</h1>
    </div>
    <section>
      <p>Below are the third-party cookies CharacterHub uses, and what they are used for.</p>
      <div class="entry">
        <input id="sentry" v-model="cookies['sen']" type="checkbox" />
        <label for="sentry"
          ><b>Sentry</b> Used to automatically send bugs/glitches that may occur while users use the site</label
        >
      </div>
      <div class="entry">
        <input id="ga" v-model="cookies['ga']" type="checkbox" />
        <label for="ga"
          ><b>Google Analytics</b> Used to see how users arrive on our site and where users drop off, to inform UI
          decisions</label
        >
      </div>
      <div class="entry">
        <input id="amplitude" v-model="cookies['amp']" type="checkbox" />
        <label for="amplitude"
          ><b>Amplitude</b> Used to see how many times users do certain actions, such as creating characters</label
        >
      </div>
      <div class="center-h">
        <ion-button class="mt-4 mx-4 wide" @click="save"> Save </ion-button>
        <br />
        <ion-button color="medium" class="mt-4 mx-4" @click="rejectAll"> Reject all cookies </ion-button>
        <ion-button color="medium" class="mt-4" @click="acceptAll"> Accept all cookies </ion-button>
      </div>
    </section>
  </ion-page>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component';
import { toast, storage } from '@/shared/native';

@Options({
  name: 'CookiesPage',
})
export default class EmailSettingsPage extends Vue {
  public get token() {
    const router = useRouter();
    return router.currentRoute.value.params.token;
  }

  public cookies: any = {
    ga: true,
    sen: true,
    amp: true,
    hot: true,
  };

  public async mounted() {
    const storedValsStr = (await storage.get('chrhub-a')) || JSON.stringify(this.cookies);
    const storedVals = JSON.parse(storedValsStr);
    for (const key in this.cookies) {
      this.cookies[key] = storedVals[key] === true;
    }
  }

  public async acceptAll() {
    for (const key in this.cookies) {
      this.cookies[key] = true;
    }
    await storage.set('chrhub-a', JSON.stringify(this.cookies));
    await toast.show('Settings saved. Reloading page...', 'nonative', 'success');
    setTimeout(() => {
      location.reload();
    }, 1000);
  }

  public async rejectAll() {
    for (const key in this.cookies) {
      this.cookies[key] = false;
    }
    await storage.set('chrhub-a', JSON.stringify(this.cookies));
    await toast.show('Settings saved. Reloading page...', 'nonative', 'success');
    setTimeout(() => {
      location.reload();
    }, 1000);
  }

  public async save() {
    await storage.set('chrhub-a', JSON.stringify(this.cookies));
    await toast.show('Settings saved. Reloading page...', 'nonative', 'success');
    setTimeout(() => {
      location.reload();
    }, 1000);
  }
}
</script>
<style lang="sass" scoped>
.entry
  margin-bottom: 10px
  padding: 0.3em 0

.header
  text-align: center

input
  margin-right: 8px

.wide
  width: 40%
  text-align: center

.center-h
  align-items: center
  text-align: center
</style>
